

import React from "react";
import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';

export const onServiceWorkerUpdateReady = () => window.location.reload();

export const onRouteUpdate = loc => {
    const { state } = loc.location;
    if (state && state.refocusId) {
        const elem = document.getElementById(state.refocusId);
        if (elem) {
            elem.focus();
        }
    }
};

const onRedirectCallback = (appState) => {
    // Use Gatsby's navigate method to replace the url
    navigate(appState?.returnTo || '/dashboard/servers', { replace: true });
};

export const wrapRootElement = ({ element }) => {

    return (
        <Auth0Provider
            domain={process.env.GATSBY_AUTH0_DOMAIN}
            clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
            redirectUri={window.location.origin + '/dashboard/servers'}
            onRedirectCallback={onRedirectCallback}
        >
            {element}
        </Auth0Provider>
    );
};

